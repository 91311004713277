
html, body {
  font-family: "gill_sansregular";
  font-size: 16px;
  overscroll-behavior: none;
}

html {
  width: 100%;
  height: 100%;
  position: relative;
}

body {
  overflow-x: hidden !important;

}

.sweet-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #CD1719;
}

.sweet-sending {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  margin-top: 30%;
  background-color: #FFF;
}

.homeGrid {
  height: 100%;
  position: absolute;
  display: flex;
  align-items: stretch;
}

.homeGrid .pageLeft, .pageGrid .pageLeft {
  display: flex;
  flex: 1;
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: auto;
  height: 100%;
  background-color: #CD1719;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 50%;
  bottom: 0;
  left: 0;
}

.homeGrid .pageLeft img {
  height: 75%;
  height: 75%;
}

.pageGrid .pageLeft {
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.homeGrid .pageRight {
  background: url('../images/maan-kwartier.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}


.pageGrid .pageRight {
  position: relative;
  left: 50%;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll !important;
}

.pageRight .content {
  overflow-y: scroll !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10%;
}



.mainMenu {
  position: fixed;
  top: 0;
  right: 0;
  height: auto;
  left: 50%;
  background-color: rgba(255,255,255,0.95);
  z-index: 1000;

}

.homeGrid .mainMenu {
  background-color: rgba(255,255,255,0);
}
.mainMenu ul {
  display: flex;
  margin-left: -20px;
  flex-direction: row;
  justify-content: space-evenly;
}
.mainMenu ul li {
  display: inline-block;
  margin: 5px;
  white-space: nowrap;
  font-family: gill_sansbold;
  text-transform: uppercase;
  font-size: 14px;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
}

.mobileMenu ul {
  margin: 0px;
  margin-left: -40px;
  text-align: center;
  width: 98% !important;
}

.mobileMenu ul li {
  display: inline-block;
  white-space: nowrap;
  margin: 10px;
  font-family: gill_sansbold;
  text-transform: uppercase;
  font-size: 13px;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
}
.mobileMenu ul li a {
  color: #FFF;
  text-decoration: none;
  text-shadow: 0px 0px 5px rgba(0,0,0,0.4)
}


.homeGrid .mainMenu ul li a {
  color: #FFF;
  text-decoration: none;
}

.pageGrid .mainMenu ul li a {
  color: rgba(0,0,0,0.6);
  text-decoration: none;
}


.mainMenu ul li a:hover {
  color:  #CD1719;
}

.active {
  color:  #CD1719 !important;
}

p a {
  color:  #CD1719 !important;
  text-decoration: none
}

p a:hover {
  text-decoration: underline;
}


.content h2 {
  font-family: gill_sansbold;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 16px;
  color: #cd1719;
  margin-bottom: -10px;
  margin-top:40px;

  width:100%;
}

.content h3 {
  font-family: gill_sansbold;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 14px;
  color: #000;
  margin-bottom: 0;
  margin-top: 0;
  width:100%;
}

.textParagraph, .textParagraph p {
  font-family: gill_sansregular;
  margin-bottom: 10px;
  text-align: left;
  width: 100%;

}

table {
  border: none !important;
}

tr, td {
  border: none !important;
}

.project-slider {
  margin-top: 10px;
  margin-bottom: 40px;
}

.by-line {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: gill_sansbold;
}

td, th {
  font-size: 16px;
  text-align: center;
}


.mobile-home-grid-top {
  background: #cd1719;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-home-grid-top img {
  height: 75%;
}

.home-mobile-image-block {
  background: url('../images/maan-kwartier.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.mobile-image-block {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 960px) {
  .pageGrid .pageLeft {
    display: none;
  }

  .pageGrid .pageRight {
    left: 0;
    width: 95% !important;
    max-width: 95% !important;
    right: 12px;
    overflow-x: hidden !important;
    margin: 0 !important;
    padding: 10px !important;
    margin-top: -40px !important;
    padding-top: 0 !important;

  }
  .formContainer {
    padding-left: 20px;
    padding-right: 20px;
  }
  .container {
    padding-left: 20px
  }

  td {
    font-size: 12px;
    text-align: left;
  }
}

.downbox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 70%;
}

.downbox i {
  flex: 1;
  color: #FFF;
  font-size: 22px;
  align-self: flex-end;
}


@media only screen and (max-width: 320px) {
  .pageGrid .pageLeft {
    display: none;
  }
  .pageGrid .pageRight {
    left: 0;
    width: 95% !important;
    right: 12px;
    overflow-x: hidden;
    margin: 0 !important;
    padding: 10px !important;
    margin-top: -40px !important;
    padding-top: 0 !important;
  }
  .formContainer {
    padding-left: 80px;
    padding-right: 20px;
  }
  .container {
    padding-left: 20px
  }

  th {
    font-size: 10px;
  }
  td{
    margin: 0 !important;
    padding: 0 !important;
    font-size: 10px;
    text-align: right;
  }
}
